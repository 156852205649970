<template>
  <div>
    <v-card
      v-for="module in mailTemplates"
      :key="module.module"
      class="mb-10"
    >
      <v-card-title>
        {{module.module}}
      </v-card-title>
      <v-card-text>
        <v-btn
          v-for="templateName in module.mails"
          :key="templateName"
          color="primary"
          class="mr-4 mb-4"
          target="_blank"
          :to="{
            name: 'admin.create-admin-session',
            query: { path: `/mail/preview-base-template?feature=${templateName}` },
          }"
        >
          {{templateName}}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'Mail Template'
    }
  },
  data: () => ({
    mailTemplates: []
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$store.dispatch('loading/active')
      try {
        this.mailTemplates = await this.$api.collection.baseApi.mailTemplates()
      } catch (error) {
        console.warn(error)
      } finally {
        this.$store.dispatch('loading/close')
      }
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
